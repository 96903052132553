<template>
    <AModalContent class="buy-trait-modal" @close="$emit('close')" :show-close="true">
        <div class="buy-trait-modal-title">
            Trade {{ isUtility ? 'utility' : 'trait' }}
            <span>{{ trait.name }}</span>
        </div>
        <div class="buy-trait-modal-orders-title">Buy Item </div>
        <a href="javascript:void(0)" @click.prevent="buyTrait" class="buy-trait-modal-orders-create">
            Submit Offer
        </a>
        <div v-if="orders.length" class="buy-trait-modal-orders">
            <div class="buy-trait-modal-orders__header">
                <div class="buy-trait-modal-orders__header-item">Wallet</div>
                <div class="buy-trait-modal-orders__header-item">Price</div>
                <div class="buy-trait-modal-orders__header-item">Amount</div>
                <div class="buy-trait-modal-orders__header-item">Action</div>
            </div>
            <BuyTraitModalOrder v-for="(order, idx) in orders" :trait="trait" :key="idx" :order="order"
                :isUtility="isUtility" @buy="onBuy" />
        </div>
        <div v-else class="buy-trait-modal-orders-no-orders">
            No items to buy
        </div>
        <div class="buy-trait-modal-orders-title" v-tooltip="'Sell item now or list it at the price you want'">Sell Item</div>
    
        <a href="javascript:void(0)" @click.prevent="sellTrait" class="buy-trait-modal-orders-create" v-if="offChainCount > 0">
            List to Sell
        </a>
        <div v-if="buyOrders.length" class="buy-trait-modal-orders">
            <div class="buy-trait-modal-orders__header">
                <div class="buy-trait-modal-orders__header-item">Wallet</div>
                <div class="buy-trait-modal-orders__header-item">Price</div>
                <div class="buy-trait-modal-orders__header-item">Amount</div>
                <div class="buy-trait-modal-orders__header-item">Action</div>
            </div>
            <BuyTraitModalOrderSell v-for="(order, idx) in buyOrders" :trait="trait" :key="idx" :order="order"
                :isUtility="isUtility" @buy="onBuy" />
        </div>
        <div v-else class="buy-trait-modal-orders-no-orders">
            No items to sell
        </div>
    </AModalContent>
</template>
<script setup>
import { traitMarketplaceBuy } from "@/functions/trait-marketplace/trait-marketplace-buy";
import { computed, ref } from "vue";
import AModalContent from "../Modal/AModalContent.vue";
import ACounter from "../ACounter.vue";
import BuyTraitModalOrder from "../BuyTraitModal/BuyTraitModalOrder.vue";
import BuyTraitModalOrderSell from "../BuyTraitModal/BuyTraitModalOrderSell.vue";
import { ModalName } from "@/components/Modal/modal-name"
import { useModal } from "@/composables/useModal"
import { useWeb3Store } from "@/store/web3";
const props = defineProps({
    sellOrders: Array,
    buyOrders: Array,
    trait: Object,
    onSuccess: Function,
    isUtility: Boolean
})
const emit = defineEmits(['close', 'buy'])
const userWallet = computed(() => useWeb3Store().collectionWallet)
const offChainCount = computed(() => props.trait.offChainCount || 0)
const userBuy =  computed(() => props.sellOrders.find(order => order.wallet == userWallet.value))
const userSell =  computed(() => props.buyOrders.find(order => order.wallet == userWallet.value))
const orders = computed(() => {
    if (userSell.value) {
        return [userSell.value, ...props.sellOrders.filter(order => order.wallet != userWallet.value)]
    } else {
        return [...props.sellOrders.filter(order => order.wallet != userWallet.value)]
    }
})
const buyOrders = computed(() => {
    if (userBuy.value) {
        return [userBuy.value, ...props.buyOrders.filter(order => order.wallet != userWallet.value)]
    } else {
        return [...props.buyOrders.filter(order => order.wallet != userWallet.value)]
    }
})

const onBuy = () => {
    props.onSuccess()
    emit('close')
}

const sellTrait = async () => {
    emit('close')

    const currentOrder = userBuy.value

    if (currentOrder) {
        useModal().open({
            name: ModalName.SellTraitModal, props: {
                isUtility: props.isUtility,
                id: props.trait.id,
                offerId: currentOrder._id,
                price: currentOrder.price,
                amount: currentOrder.amount, 
                traitName: props.trait.name,
                onSuccess: props.onSuccess
            },
        })
    } else {
        useModal().open({
            name: ModalName.SellTraitModal, props: {
                isUtility: props.isUtility,
                id: props.trait.id,                
                traitName: props.trait.name,
                onSuccess: props.onSuccess
            }
        })
    }
}
const buyTrait = () => {
    emit('close')

    const currentOrder = userSell.value

    if (currentOrder) {
        useModal().open({
            name: ModalName.SellTraitModal, props: {
                isUtility: props.isUtility,
                id: props.trait.id,
                offerId: currentOrder._id,
                price: currentOrder.price,
                amount: currentOrder.amount,
                isBuying: true,
                traitName: props.trait.name,
                onSuccess: props.onSuccess
            },
        })
    } else {
        useModal().open({
            name: ModalName.SellTraitModal, props: {
                isUtility: props.isUtility,
                id: props.trait.id,
                isBuying: true,
                traitName: props.trait.name,
                onSuccess: props.onSuccess
            }
        })
    }
}

</script>
<style lang="scss">
.buy-trait-modal {
    font-weight: bold;
    width: 600px;
    max-width: 90%;
    z-index: 10;
    background-color: var(--gray-light);
    border-radius: 10px;
    padding: 20px;
    min-height: 400px;
    max-height: 90%;
    overflow: auto;

    &-title {
        font-size: 24px;
        margin-bottom: 20px;

        span {
            color: var(--primary);
        }
    }

    &-orders {
        min-width: 450px;
        margin-bottom: 10px;

        &-title {
            margin-bottom: 16px;
            text-transform: uppercase;
            font-size: 18px;
        }

        &-create {
            background-color: var(--primary);
            padding: 5px 10px;
            margin-bottom: 10px;
            width: auto;
            border-radius: 5px;
            text-decoration: none;
            color: #fff;
            box-sizing: border-box;
            display: inline-block;
            transition: 0.2s;

            &:hover {
                filter: brightness(0.8);
            }
        }

        &-no-orders {
            font-size: 14px;
            opacity: 0.5;
            margin-bottom: 10px;
        }

        &__header {
            display: flex;
            margin-bottom: 5px;

            &-item {
                flex: 1;
                text-transform: uppercase;
                opacity: 0.5;
                padding: 0 3px;
                box-sizing: border-box;

                &:last-child {
                    flex: none;
                    flex-shrink: 0;
                    width: 120px;
                }
            }
        }
    }

    &-order {
        padding: 10px 0;
        border-top: 1px solid var(--border);

        display: flex;
        align-items: center;

        &:last-child {
            border-bottom: 1px solid var(--border);
        }

        &__item {
            padding: 0 3px;
            box-sizing: border-box;

            &-tooltip {
                display: block;
                color: rgba(255, 255, 255, 0.7);
                font-size: 14px;
                margin-top: 5px;
            }
        }

        &__wallet {
            flex: 1;

            a {

                color: inherit;
                ;

                &:hover {
                    color: var(--primary);
                }
            }
        }

        &__price {
            flex: 1;
        }

        &__amount {
            flex: 1;
            width: 80px;
        }

        &__action {
            flex-shrink: 0;
            width: 120px;

            &-button {
                display: block;
                width: 120px;
                text-align: center;
                box-sizing: border-box;
                padding: 5px 15px;
                background-color: var(--primary);
                border-radius: 5px;
                color: #fff;
                text-decoration: none;
                transition: 0.2s;

                &:hover {
                    filter: brightness(0.8);
                }

                &.disabled {
                    pointer-events: none;
                    opacity: 0.5;
                }
            }

            &-count {
                margin-bottom: 5px;

                &-title {
                    font-size: 14px;
                    opacity: 0.5;
                    text-transform: uppercase;
                    margin-bottom: 3px;
                }
            }
        }
    }
}
</style>