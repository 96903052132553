<template>
    <div class="buy-trait-modal-order">
        <div class="buy-trait-modal-order__wallet buy-trait-modal-order__item">
            <a target="_blank" :href="'https://opensea.io/' + order.wallet">{{ orderWallet }}</a>
        </div>
        <div class="buy-trait-modal-order__price buy-trait-modal-order__item">{{ priceWithTax }} METH <span
                class="buy-trait-modal-order__item-tooltip" v-tooltip="order.price + ' METH Order Price' + ' + ' + tax + ' METH Tax'"> 1.5%
                tax included</span></div>
        <div class="buy-trait-modal-order__amount buy-trait-modal-order__item">x{{ order.amount }}</div>
        <div class="buy-trait-modal-order__action">
            <ACounter class="buy-trait-modal-order__action-count" v-model:value="count" :max="order.amount" v-if="isBuying" />
            <a 
                class="buy-trait-modal-order__action-button" 
                v-if="!isOwnOrder" 
                :class="{ disabled: isLoading }" 
                href="#"
                @click.prevent="buyTrait"
            >
                Accept Offer
            </a>
            <a 
                class="buy-trait-modal-order__action-button" 
                v-else 
                :class="{ disabled: isLoading }" 
                href="#"
                @click.prevent="deListTrait"
            >
                Cancel Listing
            </a>
        </div>
    </div>
</template>
<script setup>
import { traitMarketplaceBuy } from "@/functions/trait-marketplace/trait-marketplace-buy";
import { traitMarketplaceRemove } from "@/functions/trait-marketplace/trait-marketplace-remove"
import { shorten } from "@/helpers/utils";
import { computed, ref } from "vue-demi";
import ACounter from "../ACounter.vue";
import { useWeb3Store } from "@/store/web3";

const emit = defineEmits(['buy'])
const props = defineProps({ order: Object, trait: Object, isUtility: Boolean })
const isBuying = ref(false)
const isLoading = ref(false)
const count = ref(1)
const orderWallet = computed(() => shorten(props.order.wallet, 4))
const isOwnOrder = computed(() => props.order.wallet == useWeb3Store().collectionWallet)
const tax = computed(() => Math.ceil(props.order.price * 0.015))
const priceWithTax = computed(() => props.order.price + tax.value)
const startBuying = () => {
    isBuying.value = true
}
const buyTrait = async () => {
    if (!isBuying.value) {
        isBuying.value = true
    } else {
        isLoading.value = true
        const success = await traitMarketplaceBuy({ isUtility: props.isUtility, offerId: props.order._id, amount: count.value, id: props.trait.id, isSelling: true })
        isLoading.value = false
        if (success) {
            emit('buy')
        }
    }
}

const deListTrait = async () => {   
    await traitMarketplaceRemove({ offerId: props.order._id, id: props.trait.id, isSelling: false })
    emit('buy')    
}
</script>
<style lang="scss"></style>